import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './init/keycloak-init.factory'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatIconModule } from '@angular/material/icon';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { AddressComponent } from './address/address.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MICaseService } from './mi-case/mi-case.service';
import { CreateCaseComponent } from './mi-case/case-create/case-create.component';
import { CaseListComponent } from './mi-case/case-list/case-list.component';
import { NgChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { CommonViewModule } from '@lib/view/common-view.module';
import { MaterialModule } from '@lib/view/material.module';
import { MiCaseComponent } from './mi-case/mi-case.component';
import { MiCaseOverviewComponent } from './mi-case/mi-case-overview/mi-case-overview.component';
import { MiCaseUb04Component } from './mi-case/mi-case-ub04/mi-case-ub04.component';
import { UB04CreateComponent } from './ub04/ub04-create/ub04-create.component';
import { MAT_LEGACY_RADIO_DEFAULT_OPTIONS as MAT_RADIO_DEFAULT_OPTIONS, MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { UB04EditDialog1Component } from './ub04/ub04-edit-dialog1/ub04-edit-dialog1.component';
import { Ub04RevenueCodeDialogComponent } from './ub04/ub04-revenue-code-dialog/ub04-revenue-code-dialog.component';
import { Ub04EditPatientDialogComponent } from './ub04/ub04-edit-patient-dialog/ub04-edit-patient-dialog.component';
import { NameSearchPromptComponent } from './search/name-search-prompt/name-search-prompt.component';
import { Ub04EditProviderDialogComponent } from './ub04/ub04-edit-provider-dialog/ub04-edit-provider-dialog.component';
import { SingleChoiceDialogComponent } from '@lib/dialog/single-choice-dialog/single-choice-dialog.component';
import {
  Ub04DiagnosisCodeDialogComponent
} from './ub04/ub04-diagnosis-code-dialog/ub04-diagnosis-code-dialog.component';
import {
  Ub04ConditionCodeDialogComponent
} from './ub04/ub04-condition-code-dialog/ub04-condition-code-dialog.component';
import { Ub04RemarksDialogComponent } from './ub04/ub04-remarks-dialog/ub04-remarks-dialog.component';
import { MiCaseItemizedComponent } from './mi-case/mi-case-itemized/mi-case-itemized.component';
import {
  Ub04OccurrenceCodeDialogComponent
} from './ub04/ub04-occurrence-code-dialog/ub04-occurrence-code-dialog.component';
import { AttachmentModule } from './mi-case/mi-case-attachment/attachment.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { Ub04ValueCodeDialogComponent } from './ub04/ub04-value-code-dialog/ub04-value-code-dialog.component';
import {
  Ub04AddProviderComponent
} from './ub04/ub04-edit-provider-dialog/ub04-add-provider/ub04-add-provider.component';
import {
  Ub04EditProcedureCodeDialogComponent
} from './ub04/ub04-edit-procedure-code-dialog/ub04-edit-procedure-code-dialog.component';
import {
  Ub04EditPayerComponentComponent
} from './ub04/ub04-payer-dialog/ub04-edit-payer-component/ub04-edit-payer-component.component';
import { MiCaseUsersComponent } from './mi-case/mi-case-users/mi-case-users.component';
import { MiCaseXfwfTaskListComponent } from './mi-case/mi-case-xfwf-task-list/mi-case-xfwf-task-list.component';
import { ItemizedCreateComponent } from './itemized/itemized-create/itemized-create.component';
import { Ub04PayerDialogComponent } from './ub04/ub04-payer-dialog/ub04-payer-dialog.component';
import { Ub04AddCompanyComponent } from './ub04/ub04-payer-dialog/ub04-add-company/ub04-add-company.component';
import {
  ItemizedRevisionListComponent
} from './itemized/itemized-revision/itemized-revision-list/itemized-revision-list.component';
import {
  ItemizedRevisionCreateComponent
} from './itemized/itemized-revision/itemized-revision-create/itemized-revision-create.component';
import {
  ItemizedLineItemComponent
} from './itemized/itemized-line-item/itemized-line-item/itemized-line-item.component';
import {
  ItemizedLineItemBulkEditorComponent
} from './itemized/itemized-line-item/itemized-line-item-bulk-editor/itemized-line-item-bulk-editor.component';
import { Ub04SelectComponent } from './mi-case/mi-case-itemized/ub04-select/ub04-select.component';
import { ActivityDiaryComponent } from './activity-diary/activity-diary.component';
import { DataEntryComponent } from './data-entry/data-entry.component';
import { DataEntryMiCaseListComponent } from './data-entry/data-entry-mi-case-list/data-entry-mi-case-list.component';
import {
  DataEntryAttachmentDialogComponent
} from './data-entry/data-entry-attachment-dialog/data-entry-attachment-dialog.component';
import { MatExpansionModule } from "@angular/material/expansion";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import {
  DataEntryServiceDateRangeDialogComponent
} from './itemized/itemized-revision/data-entry-service-date-range-dialog/data-entry-service-date-range-dialog.component';
import {
  CopyCloneLineItemDialogComponent
} from './itemized/itemized-line-item/copy-clone-line-item-dialog/copy-clone-line-item-dialog.component';
import { CommentsModule } from './comments/comments.module';
import { CaseEditComponent } from './mi-case/case-edit/case-edit.component';
import { DashboardTabulatorComponent } from './dashboard/dashboard-tabulator/dashboard-tabulator.component';
import { ClaimStatusReportComponent } from './dashboard/claim-status-report/claim-status-report.component';
import { DashboardFilterParamComponent } from './dashboard/dashboard-filter-param/dashboard-filter-param.component';
import {
  DashboardFilterParamDialogComponent
} from './dashboard/dashboard-filter-param-dialog/dashboard-filter-param-dialog.component';
import { ApiInterceptor, EnvironmentService, XfCommonModule } from "xf-common";
import { environmentValue } from "../environments/environment";
import { ActivityLogDialogComponent, ActivityLogJsonDialogComponent } from 'projects/xf-common/src/public-api';

@NgModule({
  declarations: [
    ActivityLogDialogComponent,
    ActivityLogJsonDialogComponent,
    AddressComponent,
    AppComponent,
    CaseListComponent,
    CopyCloneLineItemDialogComponent,
    CreateCaseComponent,
    DashboardComponent,
    DataEntryAttachmentDialogComponent,
    DataEntryMiCaseListComponent,
    DataEntryServiceDateRangeDialogComponent,
    HomeComponent,
    ItemizedCreateComponent,
    ItemizedLineItemComponent,
    ItemizedRevisionCreateComponent,
    ItemizedRevisionListComponent,
    MiCaseComponent,
    MiCaseOverviewComponent,
    MiCaseUb04Component,
    MiCaseItemizedComponent,
    MiCaseUsersComponent,
    NameSearchPromptComponent,
    SingleChoiceDialogComponent,
    Ub04AddCompanyComponent,
    Ub04AddProviderComponent,
    Ub04ConditionCodeDialogComponent,
    UB04CreateComponent,
    Ub04DiagnosisCodeDialogComponent,
    UB04EditDialog1Component,
    Ub04EditPatientDialogComponent,
    Ub04EditPayerComponentComponent,
    Ub04EditProcedureCodeDialogComponent,
    Ub04EditProviderDialogComponent,
    Ub04OccurrenceCodeDialogComponent,
    Ub04PayerDialogComponent,
    Ub04RemarksDialogComponent,
    Ub04RevenueCodeDialogComponent,
    Ub04ValueCodeDialogComponent,
    Ub04AddProviderComponent,
    Ub04EditProcedureCodeDialogComponent,
    MiCaseUsersComponent,
    MiCaseXfwfTaskListComponent,
    UserProfileComponent,
    ItemizedLineItemBulkEditorComponent,
    Ub04SelectComponent,
    ActivityDiaryComponent,
    DataEntryComponent,
    CaseEditComponent,
    DashboardTabulatorComponent,
    ClaimStatusReportComponent,
    DashboardFilterParamComponent,
    DashboardFilterParamDialogComponent
  ],
  imports: [
    AttachmentModule,
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    CommonViewModule,
    KeycloakAngularModule,
    HttpClientModule,
    MatTabsModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTableModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogModule,
    MatCardModule,
    MatGridListModule,
    MaterialModule,
    NgChartsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    NgxExtendedPdfViewerModule,
    CommentsModule,
    XfCommonModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, EnvironmentService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'accent' } },
    MICaseService,
    {
      provide: EnvironmentService,
      useValue: environmentValue
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
